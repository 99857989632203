let multiSmashLevels = [
    {
        "level": 1,
        "upgrade": 1,
        "price": 100
    },
    {
        "level": 2,
        "upgrade": 2,
        "price": 500
    },
    {
        "level": 3,
        "upgrade": 3,
        "price": 1000
    },
    {
        "level": 4,
        "upgrade": 4,
        "price": 1500
    },
    {
        "level": 5,
        "upgrade": 5,
        "price": 2000
    },
    {
        "level": 6,
        "upgrade": 6,
        "price": 2500
    },
    {
        "level": 7,
        "upgrade": 7,
        "price": 3000
    },
    {
        "level": 8,
        "upgrade": 8,
        "price": 4000
    },
    {
        "level": 9,
        "upgrade": 9,
        "price": 5000
    },
    {
        "level": 10,
        "upgrade": 69,
        "price": 10000
    }
];

let energyLimitLevels = [
    {
        "level": 1,
        "upgrade": 500,
        "price": 100
    },
    {
        "level": 2,
        "upgrade": 600,
        "price": 400
    },
    {
        "level": 3,
        "upgrade": 700,
        "price": 4000
    },
    {
        "level": 4,
        "upgrade": 800,
        "price": 8000
    },
    {
        "level": 5,
        "upgrade": 900,
        "price": 20000
    },
    {
        "level": 6,
        "upgrade": 1000,
        "price": 30000
    },
    {
        "level": 7,
        "upgrade": 2024,
        "price": 40000
    },
    {
        "level": 8,
        "upgrade": 3333,
        "price": 50000
    },
    {
        "level": 9,
        "upgrade": 4444,
        "price": 1000000
    },
    {
        "level": 10,
        "upgrade": 6969,
        "price": 5000000
    }
];

let rechargingSpeedLevels = [
    {
        "level": 1,
        "upgrade": 1000,
        "price": 300
    },
    {
        "level": 2,
        "upgrade": 900,
        "price": 600
    },
    {
        "level": 3,
        "upgrade": 800,
        "price": 6000
    },
    {
        "level": 4,
        "upgrade": 700,
        "price": 12000
    },
    {
        "level": 5,
        "upgrade": 500,
        "price": 20000
    },
    {
        "level": 6,
        "upgrade": 400,
        "price": 40000
    },
    {
        "level": 7,
        "upgrade": 300,
        "price": 60000
    },
    {
        "level": 8,
        "upgrade": 200,
        "price": 100000
    },
    {
        "level": 9,
        "upgrade": 100,
        "price": 500000
    },
    {
        "level": 10,
        "upgrade": 50,
        "price": 1000000
    }
];

export {multiSmashLevels, energyLimitLevels, rechargingSpeedLevels};