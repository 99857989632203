import CoinIcon from '../images/CoinIcon.js';
import './styles/header.css';
import { NavLink } from "react-router-dom";

const menuIcon = <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="0.5" y="0.5" width="35" height="35" rx="3.5" stroke="white"/>
<line x1="9" y1="10" x2="27" y2="10" stroke="white" strokeWidth="4" strokeLinecap="round"/>
<line x1="9" y1="18" x2="27" y2="18" stroke="white" strokeWidth="4" strokeLinecap="round"/>
<line x1="9" y1="26" x2="27" y2="26" stroke="white" strokeWidth="4" strokeLinecap="round"/>
</svg>

function TapsCounter({taps, page}) {
  return (
    <div className="header-container">
        <NavLink className="menuButton" to="/profile">{menuIcon}</NavLink>
        {page === 'ref' ?
            <div className='header-wrapper-ref'>
                <span>{taps}</span>
                <span>Referrals</span>
            </div> 
            : 
            <div className='header-wrapper'>
                <CoinIcon />
                <span>{taps}</span>
            </div> 
        }
    </div>
  );
}

export default TapsCounter;
