import '../App.css';
import Header from '../components/header';
import React, { useEffect, useState } from 'react'
import Tasks from '../components/tasks';
import axios from 'axios';
import MyReferrals from '../components/myReferrals';
import ModalRef from '../components/ModalRef';
import { useNavigate } from 'react-router-dom';

function Ref() {
  const webApp = window.Telegram.WebApp
  const user = webApp.initDataUnsafe?.user
  const [referralCode, setReferralCode] = useState();
  const [referredCount, setReferredCount] = useState(0);
  const [close, setClose] = useState(true);
  const [modalInfo, setModalInfo] = useState({});
  const navigate = useNavigate();

  const getReferral = async (user) => {
    if (user) {
      try {
        let data = JSON.stringify({
          "telegramId": user.id
        });

        let config = {
          method: 'post',
          maxBodyLength: Infinity,
          url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/user/generate-referral`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };

        const response = await axios.request(config);
        console.log('Got my ref: ', response.data.referralCode)
        setReferralCode(response.data.referralCode)
        setReferredCount(response.data.referredCount)
      } catch (error) {
        console.log(error);
      }
    }
  };

  const copyToClipboard = () => {
    const url = `https://t.me/GodlenMemeTapper_bot/MemeTapper?startapp=${referralCode}&startApp=${referralCode}`;
  
    if (navigator.clipboard && window.isSecureContext) {
      // Use the modern clipboard API
      navigator.clipboard.writeText(url)
        .then(() => {
          alert('Copied to clipboard!');
        })
        .catch((err) => {
          console.error('Failed to copy: ', err);
        });
    } else {
      // Use fallback method for older browsers
      const textArea = document.createElement('textarea');
      textArea.value = url;
      textArea.style.position = 'fixed';  // Prevent scrolling to bottom of the page in MS Edge.
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = '0';
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      document.body.appendChild(textArea);
      textArea.focus();
      textArea.select();
  
      try {
        const successful = document.execCommand('copy');
        if (successful) {
          alert('Copied to clipboard!');
        } else {
          console.error('Failed to copy using execCommand');
        }
      } catch (err) {
        console.error('Failed to copy: ', err);
      }
  
      document.body.removeChild(textArea);
    }
  };
  

  useEffect(() => {
    
    getReferral(user)

  }, []);

  useEffect(() => {
    const handleBackButtonClick = () => {
      navigate(`/smash`);
    };

    window.Telegram.WebApp.onEvent('backButtonClicked', handleBackButtonClick);

    // Cleanup function to remove event listener
    return () => {
      window.Telegram.WebApp.offEvent('backButtonClicked', handleBackButtonClick);
    };
  }, []);

  return (
    <>
      <Header taps={referredCount} page="ref" />
      <div className='page-body'>
        <MyReferrals setClose={setClose} />
        <Tasks message="Currently you don’t have referrals" />
        <ModalRef close={close} copyToClipboard={copyToClipboard} modalInfo={modalInfo} setClose={setClose} />
      </div>
    </>
  );
}

export default Ref;
