import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useTelegram } from "./hooks/useTelegram";
import Page from "./components/page";
import './App.css';
import './components/styles/wallet.css';
import { Provider } from 'react-redux';
import Wallet from './pages/Wallet';
import Smash from './pages/Smash';
import Ref from './pages/Ref';
import store from './redux/store';
import Boost from './pages/Boost';
import Stats from './pages/Stats';
import { energyLimitLevels, rechargingSpeedLevels, multiSmashLevels } from './constants/upgrades';
import TasksPage from './pages/Tasks';
import TaskPage from './pages/Task';
import WelcomePage from './pages/Welcome';
import ProfilePage from './pages/Profile';
import WelcomeDesktop from './components/welcomeDesktop/welcomeDesktop';
import Loader from './components/Loader';

function App({ pageProps }) {
  const [energy, setEnergy] = useState(parseInt(localStorage.getItem("energy")) || 500);
  const [taps, setTaps] = useState(parseInt(localStorage.getItem("smashes")) || 0);
  const [multi, setMulti] = useState(parseInt(localStorage.getItem("multiply")) || 1);
  const [clicks, setClicks] = useState([]);
  const [loading, setLoading] = useState(true);
  const energyLimitparseInt = parseInt(localStorage.getItem("energyLimit"));
  const rechargingSpeed = parseInt(localStorage.getItem("rechargingSpeed"));
  const { user } = useTelegram();
  let lastClickTime = null;

  const getCurrentEvalue = (lvl) => {
    const currentLevel = energyLimitLevels.find(level => level.level === parseInt(lvl));
    return currentLevel ? currentLevel.upgrade : 1;
  };

  const getCurrentRvalue = (lvl) => {
    const currentLevel = rechargingSpeedLevels.find(level => level.level === parseInt(lvl));
    return currentLevel ? currentLevel.upgrade : 1;
  };

  const energyLimit = getCurrentEvalue(energyLimitparseInt);


  useEffect(() => {
    const handleTouchMove = (event) => {
      event.preventDefault();
    };

    document.addEventListener('touchmove', handleTouchMove, { passive: false });

    return () => {
      document.removeEventListener('touchmove', handleTouchMove);
    };
  }, []);

  useEffect(() => {
    const clickTimer = setTimeout(() => {
      lastClickTime = null; // Reset lastClickTime after 1 second
    }, 1000);
  
    return () => clearTimeout(clickTimer);
  }, [clicks]);
  
  useEffect(() => {
    console.log('Tapped, check if need recharge.');
    const currentEnergy = parseInt(localStorage.getItem("energy"), 10);
    const energyLimit = getCurrentEvalue(energyLimitparseInt);
  
    if (currentEnergy < energyLimit) {
      console.log('currentEnergy < energyLimit: ', currentEnergy < energyLimit);
      const energyInterval = setInterval(() => {
        const updatedCurrentEnergy = parseInt(localStorage.getItem("energy"), 10);
  
        if (updatedCurrentEnergy < energyLimit) {
          const newEnergy = updatedCurrentEnergy + 1;
          localStorage.setItem("energy", JSON.stringify(newEnergy));
          setEnergy(newEnergy);
        } else {
          clearInterval(energyInterval);  // Stop the interval if energy limit is reached
        }
      }, getCurrentRvalue(rechargingSpeed));
  
      return () => clearInterval(energyInterval);
    }
  }, [taps, energy]); // Add necessary dependencies if needed

  useEffect(() => {
    window.Telegram.WebApp.expand();
    window.Telegram.WebApp.disableClosingConfirmation();
    window.Telegram.WebApp.setBackgroundColor('#FCECAE');
    localStorage.setItem("user", JSON.stringify(user?.id));
    window.Telegram.WebApp.BackButton.show();
    setLoading(false); // Set loading to false after initialization
  }, [user]);
  

  if (window.Telegram.WebApp.platform === 'unknown') {
    return (
      <Provider store={store}>
        <Router {...pageProps}>
          <Routes>
            <Route path="/" element={<WelcomeDesktop />} />
            <Route path="/wallet/:id" element={<Wallet />} />
          </Routes>
        </Router>
      </Provider>
    );
  };

  if (loading) {
    return <Loader />;
  }

  if (user) {
    return (
      <Provider store={store}>
        <Router {...pageProps}>
          <Routes>
            <Route path="/" element={<Navigate to="/welcome" replace />} />
            <Route path="/smash" element={<Page><Smash user={user?.id} energy={energy} setEnergy={setEnergy} setTaps={setTaps} taps={taps} clicks={clicks} setClicks={setClicks} multi={multi} setMulti={setMulti} /></Page>} />
            <Route path="/ref" element={<Page><Ref /></Page>} />
            <Route path="/wallet/:id" element={<Page><Wallet /></Page>} />
            <Route path="/welcome" element={<WelcomePage taps={taps} setTaps={setTaps} energy={energy} setEnergy={setEnergy} />} />
            <Route path="/tasks" element={<Page><TasksPage setTaps={setTaps} taps={taps} energy={energy} /></Page>} />
            <Route path="/task" element={<TaskPage setTaps={setTaps} taps={taps} energy={energy} />} />
            <Route path="/boost" element={<Page><Boost setEnergy={setEnergy} energy={energy} energyLimit={energyLimit} setTaps={setTaps} taps={taps} /></Page>} />
            <Route path="/stats" element={<Page><Stats energy={energy} taps={taps} /></Page>} />
            <Route path="/profile" element={<Page><ProfilePage /></Page>} />
          </Routes>
        </Router>
      </Provider>
    );
  }

  return (
    <Provider store={store}>
      <Router {...pageProps}>
        <Routes>
          <Route path="/wallet/:id" element={<Wallet />} />
          <Route path="/welcome" element={<WelcomeDesktop />} />
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
