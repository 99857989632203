import React, { useState } from 'react'
import PropTypes from 'prop-types'
import './header.sass'
import logo from '../../../images/logo.png'

const gift = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.5 29.5H6.5C6.23478 29.5 5.98043 29.3946 5.79289 29.2071C5.60536 29.0196 5.5 28.7652 5.5 28.5V15.5C5.5 15.2348 5.60536 14.9804 5.79289 14.7929C5.98043 14.6054 6.23478 14.5 6.5 14.5H25.5C25.7652 14.5 26.0196 14.6054 26.2071 14.7929C26.3946 14.9804 26.5 15.2348 26.5 15.5V28.5C26.5 28.7652 26.3946 29.0196 26.2071 29.2071C26.0196 29.3946 25.7652 29.5 25.5 29.5Z" fill="#EF4A4C"/>
<path d="M23.5 14.5H5.5V16.5H20.5C21.2956 16.5 22.0587 16.8161 22.6213 17.3787C23.1839 17.9413 23.5 18.7044 23.5 19.5V29.5H25.5C25.7652 29.5 26.0196 29.3946 26.2071 29.2071C26.3946 29.0196 26.5 28.7652 26.5 28.5V14.5H23.5Z" fill="#E0313F"/>
<path d="M26.5 14.5H5.5C5.23478 14.5 4.98043 14.3946 4.79289 14.2071C4.60536 14.0196 4.5 13.7652 4.5 13.5V8.5C4.5 8.23478 4.60536 7.98043 4.79289 7.79289C4.98043 7.60536 5.23478 7.5 5.5 7.5H26.5C26.7652 7.5 27.0196 7.60536 27.2071 7.79289C27.3946 7.98043 27.5 8.23478 27.5 8.5V13.5C27.5 13.7652 27.3946 14.0196 27.2071 14.2071C27.0196 14.3946 26.7652 14.5 26.5 14.5Z" fill="#EF4A4C"/>
<path d="M12.5 7.5H9.5C8.96957 7.5 8.46086 7.71071 8.08579 8.08579C7.71071 8.46086 7.5 8.96957 7.5 9.5V11.5L9 10.5L10.5 11.5V9.5C10.5 8.96957 10.7107 8.46086 11.0858 8.08579C11.4609 7.71071 11.9696 7.5 12.5 7.5ZM19.5 7.5H22.5C23.0304 7.5 23.5391 7.71071 23.9142 8.08579C24.2893 8.46086 24.5 8.96957 24.5 9.5V11.5L23 10.5L21.5 11.5V9.5C21.5 8.96957 21.2893 8.46086 20.9142 8.08579C20.5391 7.71071 20.0304 7.5 19.5 7.5Z" fill="#FECB5B"/>
<path d="M9.5 8H11.19C11.542 7.693 11.996 7.5 12.5 7.5H9.5C8.96957 7.5 8.46086 7.71071 8.08579 8.08579C7.71071 8.46086 7.5 8.96957 7.5 9.5V10C7.5 9.46957 7.71071 8.96086 8.08579 8.58579C8.46086 8.21071 8.96957 8 9.5 8ZM22.5 7.5H19.5C20.004 7.5 20.459 7.693 20.81 8H22.5C23.0304 8 23.5391 8.21071 23.9142 8.58579C24.2893 8.96086 24.5 9.46957 24.5 10V9.5C24.5 8.96957 24.2893 8.46086 23.9142 8.08579C23.5391 7.71071 23.0304 7.5 22.5 7.5Z" fill="#F8B545"/>
<path d="M13.5 7.5H18.5V14.5H13.5V7.5ZM14.5 14.5H17.5V29.5H14.5V14.5Z" fill="#FECB5B"/>
<path d="M14.5 14.5H17.5V16.5H14.5V14.5Z" fill="#F8B545"/>
<path d="M21.802 2.50793C20.474 2.60993 19.5 3.82193 19.5 5.15393V5.49993C19.5 5.76515 19.3946 6.0195 19.2071 6.20704C19.0196 6.39457 18.7652 6.49993 18.5 6.49993V6.74993H19.786C20.751 6.74993 21.536 5.96493 21.536 4.99993C21.536 4.93363 21.5623 4.87004 21.6092 4.82315C21.6561 4.77627 21.7197 4.74993 21.786 4.74993C21.8523 4.74993 21.9159 4.77627 21.9628 4.82315C22.0097 4.87004 22.036 4.93363 22.036 4.99993C22.036 6.23993 21.026 7.24993 19.786 7.24993H18.5V7.49993H21.846C23.178 7.49993 24.39 6.52593 24.492 5.19793C24.5208 4.83736 24.471 4.47483 24.346 4.1354C24.221 3.79597 24.0238 3.48771 23.768 3.23194C23.5122 2.97617 23.204 2.77897 22.8645 2.65396C22.5251 2.52896 22.1626 2.47914 21.802 2.50793Z" fill="#FECB5B"/>
<path d="M21.802 2.50793C20.474 2.60993 19.5 3.82193 19.5 5.15393V5.49993C19.5 5.76515 19.3946 6.0195 19.2071 6.20704C19.0196 6.39457 18.7652 6.49993 18.5 6.49993V6.74993H19.786C20.751 6.74993 21.536 5.96493 21.536 4.99993C21.536 4.93363 21.5623 4.87004 21.6092 4.82315C21.6561 4.77627 21.7197 4.74993 21.786 4.74993C21.8523 4.74993 21.9159 4.77627 21.9628 4.82315C22.0097 4.87004 22.036 4.93363 22.036 4.99993C22.036 6.23993 21.026 7.24993 19.786 7.24993H18.5V7.49993H21.846C23.178 7.49993 24.39 6.52593 24.492 5.19793C24.5208 4.83736 24.471 4.47483 24.346 4.1354C24.221 3.79597 24.0238 3.48771 23.768 3.23194C23.5122 2.97617 23.204 2.77897 22.8645 2.65396C22.5251 2.52896 22.1626 2.47914 21.802 2.50793Z" fill="#FECB5B"/>
<path d="M22.089 2.51709C22.5409 2.73418 22.9168 3.08271 23.1673 3.51702C23.4178 3.95133 23.5314 4.45117 23.493 4.95109C23.391 6.27909 22.179 7.25309 20.847 7.25309H18.5V7.50009H21.846C23.178 7.50009 24.39 6.52609 24.492 5.19809C24.518 4.8626 24.4757 4.52533 24.3677 4.20664C24.2597 3.88795 24.0882 3.59446 23.8636 3.34388C23.639 3.09331 23.366 2.89086 23.061 2.74875C22.756 2.60665 22.4253 2.52784 22.089 2.51709Z" fill="#F8B545"/>
<path d="M12.2139 7.24993C10.9739 7.24993 9.96393 6.23993 9.96393 4.99993C9.96393 4.93363 9.99027 4.87004 10.0372 4.82315C10.084 4.77627 10.1476 4.74993 10.2139 4.74993C10.2802 4.74993 10.3438 4.77627 10.3907 4.82315C10.4376 4.87004 10.4639 4.93363 10.4639 4.99993C10.4639 5.96493 11.2489 6.74993 12.2139 6.74993H13.4999V6.49993C13.2347 6.49993 12.9804 6.39457 12.7928 6.20704C12.6053 6.0195 12.4999 5.76515 12.4999 5.49993V5.15393C12.4999 3.82193 11.5259 2.60993 10.1979 2.50793C9.83736 2.47914 9.47483 2.52896 9.1354 2.65396C8.79597 2.77897 8.48772 2.97617 8.23194 3.23194C7.97617 3.48771 7.77897 3.79597 7.65396 4.1354C7.52896 4.47483 7.47914 4.83736 7.50793 5.19793C7.60993 6.52593 8.82193 7.49993 10.1539 7.49993H13.4999V7.24993H12.2139Z" fill="#FECB5B"/>
<path d="M7.5 28C7.77614 28 8 27.7761 8 27.5C8 27.2239 7.77614 27 7.5 27C7.22386 27 7 27.2239 7 27.5C7 27.7761 7.22386 28 7.5 28Z" fill="#004463"/>
<path d="M9.5 28C9.77614 28 10 27.7761 10 27.5C10 27.2239 9.77614 27 9.5 27C9.22386 27 9 27.2239 9 27.5C9 27.7761 9.22386 28 9.5 28Z" fill="#004463"/>
<path d="M11.5 28C11.7761 28 12 27.7761 12 27.5C12 27.2239 11.7761 27 11.5 27C11.2239 27 11 27.2239 11 27.5C11 27.7761 11.2239 28 11.5 28Z" fill="#004463"/>
<path d="M18.5 7.5H13.5V6.5C13.5 6.23478 13.6054 5.98043 13.7929 5.79289C13.9804 5.60536 14.2348 5.5 14.5 5.5H17.5C17.7652 5.5 18.0196 5.60536 18.2071 5.79289C18.3946 5.98043 18.5 6.23478 18.5 6.5V7.5Z" fill="#FECB5B"/>
<path d="M21.7919 4.75009H21.7859L21.7919 4.75309V4.75009ZM19.7859 6.75009C19.9769 6.75009 20.1579 6.71209 20.3299 6.65509C20.3549 6.64709 20.3819 6.64109 20.4059 6.63109C20.5679 6.56909 20.7169 6.48309 20.8519 6.37809L20.9149 6.32609C21.0479 6.21209 21.1669 6.08209 21.2609 5.93309L21.2779 5.90109C21.3739 5.74109 21.4499 5.56709 21.4919 5.38009C21.4999 5.33609 21.5039 5.29009 21.5119 5.24509C21.5229 5.16509 21.5359 5.08409 21.5359 5.00009C21.5359 4.93379 21.5623 4.8702 21.6091 4.82331C21.656 4.77643 21.7196 4.75009 21.7859 4.75009H21.2799C21.2136 4.75009 21.15 4.77643 21.1031 4.82331C21.0563 4.8702 21.0299 4.93379 21.0299 5.00009C21.0299 5.96509 20.2449 6.75009 19.2799 6.75009H19.7859ZM9.91092 2.51709C9.45907 2.73422 9.08336 3.08279 8.833 3.51711C8.58265 3.95144 8.46934 4.45126 8.50792 4.95109C8.60992 6.27909 9.82192 7.25309 11.1539 7.25309H13.4999V7.50009H10.1539C8.82192 7.50009 7.60992 6.52609 7.50792 5.19809C7.48194 4.8626 7.52422 4.52533 7.63222 4.20664C7.74022 3.88795 7.9117 3.59446 8.13629 3.34388C8.36088 3.09331 8.63392 2.89086 8.93894 2.74875C9.24395 2.60665 9.5746 2.52784 9.91092 2.51709Z" fill="#F8B545"/>
<path d="M10.208 4.75H10.214L10.208 4.753V4.75ZM12.214 6.75C12.023 6.75 11.842 6.712 11.67 6.655C11.645 6.647 11.618 6.641 11.594 6.631C11.4336 6.5692 11.2833 6.48393 11.148 6.378L11.085 6.326C10.9507 6.21305 10.834 6.08057 10.739 5.933L10.722 5.901C10.6233 5.73988 10.551 5.56399 10.508 5.38C10.5 5.336 10.496 5.29 10.488 5.245C10.477 5.165 10.464 5.084 10.464 5C10.464 4.9337 10.4377 4.87011 10.3908 4.82322C10.3439 4.77634 10.2803 4.75 10.214 4.75H10.72C10.7863 4.75 10.8499 4.77634 10.8968 4.82322C10.9437 4.87011 10.97 4.9337 10.97 5C10.97 5.965 11.755 6.75 12.72 6.75H12.214Z" fill="#F8B545"/>
</svg>

const telegramIcon = <svg width="28" height="28" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.9253 4.35556e-05C11.6948 0.0197862 7.64431 1.71421 4.65986 4.71265C1.67541 7.7111 -4.60654e-05 11.7695 9.49913e-10 16C9.49921e-10 20.2435 1.68571 24.3132 4.68629 27.3137C7.68687 30.3143 11.7565 32 16 32C20.2435 32 24.3131 30.3143 27.3137 27.3137C30.3143 24.3132 32 20.2435 32 16C32 11.7566 30.3143 7.68692 27.3137 4.68633C24.3131 1.68575 20.2435 4.35556e-05 16 4.35556e-05C15.9751 -1.45185e-05 15.9502 -1.45185e-05 15.9253 4.35556e-05ZM22.5413 9.63204C22.6747 9.62938 22.9693 9.66271 23.1613 9.81871C23.2889 9.9295 23.3703 10.0841 23.3893 10.252C23.4107 10.376 23.4373 10.66 23.416 10.8814C23.176 13.412 22.1333 19.5507 21.6027 22.384C21.3787 23.584 20.9373 23.9854 20.5093 24.024C19.5813 24.1107 18.876 23.4107 17.976 22.8214C16.568 21.8974 15.772 21.3227 14.4053 20.4214C12.8253 19.3814 13.8493 18.808 14.7493 17.8747C14.9853 17.6294 19.0787 13.9054 19.1587 13.568C19.168 13.5254 19.1773 13.368 19.084 13.2854C18.9907 13.2027 18.852 13.2307 18.752 13.2534C18.6107 13.2854 16.3613 14.7734 12.004 17.7134C11.364 18.1534 10.7867 18.3667 10.268 18.3534C9.69733 18.3427 8.59867 18.032 7.78133 17.7667C6.77867 17.44 5.98267 17.268 6.052 16.7147C6.088 16.4267 6.48533 16.132 7.24267 15.8307C11.9067 13.7987 15.016 12.4587 16.5733 11.812C21.016 9.96404 21.94 9.64271 22.5413 9.63204Z" fill="black"/>
</svg>

const xIcon = <svg width="29" height="28" viewBox="0 0 32 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M25.2013 0.537109H30.108L19.388 12.7904L32 29.4611H22.1253L14.392 19.3491L5.54133 29.4611H0.632L12.0987 16.3544L0 0.538443H10.1253L17.116 9.78111L25.2013 0.537109ZM23.48 26.5251H26.1987L8.648 3.31978H5.73067L23.48 26.5251Z" fill="black"/>
</svg>

const menu = [
  {
    title: 'About',
    a: 'https://godlenfish.com/#about'
  },
  {
    title: 'Godlentokenomics',
    a: 'https://godlenfish.com/#godlenomics'
  },
  {
    title: 'MemeRaids',
    a: 'https://godlenfish.com/memeraids'
  }
]

const menu2 = [
  {
    title: 'Airdrop',
    a: 'https://godlenfish.com/airdrop',
    icon: gift
  },
  {
    title: 'Referral',
    a: 'https://godlenfish.com/referral'
  },
  {
    title: 'Staking',
    a: 'https://godlenfish.com/staking'
  }
]

const menuSocial = [
  {
    a: 'https://twitter.com/godlen_memecoin',
    icon: xIcon
  },
  {
    a: 'https://t.me/godlenfish',
    icon: telegramIcon
  }
]

// const menuMobile = [
//   {
//     external: false,
//     title: 'About',
//     a: '#features'
//   },
//   {
//     external: false,
//     title: 'Godlentokenomics',
//     a: '#open-world'
//   },
//   {
//     external: false,
//     title: 'MemeRaids',
//     a: '#why-us'
//   }
// ]

const burger = <svg width="35" height="28" viewBox="0 0 35 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="2" y1="2" x2="33" y2="2" stroke="#1A1D1D" strokeWidth="4" strokeLinecap="round"/>
<line x1="2" y1="14" x2="33" y2="14" stroke="#1A1D1D" strokeWidth="4" strokeLinecap="round"/>
<line x1="2" y1="26" x2="33" y2="26" stroke="#1A1D1D" strokeWidth="4" strokeLinecap="round"/>
</svg>

const close = <svg width="27" height="28" viewBox="0 0 27 28" fill="none" xmlns="http://www.w3.org/2000/svg">
<line x1="2" y1="24.9204" x2="23.9203" y2="3.00004" stroke="black" strokeWidth="4" strokeLinecap="round"/>
<line x1="2.95404" y1="2.62573" x2="24.8743" y2="24.546" stroke="black" strokeWidth="4" strokeLinecap="round"/>
</svg>

const Header = ({ siteTitle }) => {
  const [menuBurger, setMenuBurger] = useState(false)

  const burgerHandler = () => {
    setMenuBurger(menuBurger => !menuBurger)
  }

  return (
  <header
    style={{
      margin: '0 auto',
      padding: '0',
      paddingBottom: '0px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    }}
  >
  <div className="wrapper">
    <div className='menu'>
      <a
        className="header-logo-a"
        href="/"
      >
        <img
          src={logo}
          width={73}
          alt="The Godlenfish logo"
        />
      </a>
      <ul>
      {menu.map((x, index) => (
        <li key={index}>
          <a
            href={x.a}
              >
            {x.title}
          </a>
      </li>
      ))}
      </ul>
    </div>
    <div className='menu'>
      <ul>
        {menu2.map((x, index) => (
          <li key={index}>
            <a
              href={x.a}
                >
              {x.icon}
              {x.title}
            </a>
        </li>
        ))}
        <li>
          <div className="airdropButton"><a href="https://godlenfish.com/tools"><span>GODLEN utility</span></a></div>
        </li>
        {/* <li>
          <WalletMultiButton />
        </li> */}
        {menuSocial.map((x, index) => (
          <li key={index}>
            <a
              href={x.a}
                >
              {x.icon}
              {x.title}
            </a>
        </li>
        ))}
      </ul>
    </div>
  </div>
  { menuBurger
    ? <div className="mobile-menu">
    <img
          src={logo}
          width={73}
          alt="The Godlenfish logo"
        />
    <div className="mobile-menu-wrapper">
      <ul className="mobile-menu-small">
      {menu.map((x, index) => (
          <li onClick={() => burgerHandler()} className="mobile-menu-list" key={index}>
            {x.external
              ? <a
              href={x.a}
              target="_blank" rel="noreferrer"
            >
            {x.title}
            </a>
              : <a
              href={x.a}
                >
              {x.title}
            </a>}
        </li>
      ))}
        </ul>
    </div>
    <div className="mobile-menu-social-wrapper">
    <ul>
        {menu2.map((x, index) => (
          <li onClick={() => burgerHandler()} className="mobile-menu-list" key={index}>
            {x.external
              ? <a
              href={x.a}
              target="_blank" rel="noreferrer"
            >
            {x.title}
            </a>
              : <a
              href={x.a}
                >
              {x.title}
            </a>}
        </li>
        ))}
        <li>
          <div className="airdropButton"><a href="https://godlenfish.com/tools"><span>GODLEN utility</span></a></div>
        </li>
      </ul>
    </div>
  </div>
    : null
  }
  <button onClick={() => burgerHandler()} className="mobile-burger" aria-label="Burger menu open">
        {menuBurger ? close : burger}
  </button>
  </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string
}

Header.defaultProps = {
  siteTitle: ''
}

export default Header
