import Footer from "./footer";
import React from "react";

const Page = ({children}) => {

    return (
        <>
            {children}
            <Footer />
        </>
      );
};

export default Page;
