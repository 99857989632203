import '../App.css';
import React from 'react'
import axios from 'axios';
import { useMemo, useEffect, useState } from 'react'
import {useTelegram} from "../hooks/useTelegram";
import { useLocation } from 'react-router-dom';
import {
  ConnectionProvider,
  WalletProvider
} from '@solana/wallet-adapter-react'
import {
  WalletModalProvider
} from '@solana/wallet-adapter-react-ui'
import * as web3 from '@solana/web3.js'
import { useWallet } from '@solana/wallet-adapter-react'
import Popup from 'reactjs-popup'
import WalletConnect from '../components/walletConnect'

function Wallet() {
  const [open, setOpen] = useState(true)
  const {webApp} = useTelegram()
  const wallet = useWallet()
  const endpoint = web3.clusterApiUrl('mainnet-beta')
  const url ='https://t.me/GodlenMiniGame_bot/godlengame'
  const wallets = useMemo(() => [], [])
  const [pKey, setPKey] = useState();
  const location = useLocation();
  const currentUrl = location.pathname;
  const match = currentUrl.match(/\/wallet\/(\d+)/);
  const id = match ? match[1] : null;

  const closeModal = () => setOpen(false)

  const handleOpenTelegram = () => {
    webApp.openTelegramLink(url)
  }

  const handleWallet = async () => {
    try {
      await wallet.connect()
      mergeWallet()
    } catch (error) {
      setOpen(o => !o)
      console.error('Error connecting wallet:', error)
      // Handle error
    }
  }

  const handleDisconect = async () => {
    try {
      await wallet.disconnect('phantom')
      setPKey('')
    } catch (error) {
      console.error('Error connecting wallet:', error)
      // Handle error
    }
  }

  const getWallet = () => {
    let config = {
      method: 'get',
      maxBodyLength: Infinity,
      url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/user/${id}`,
      headers: {
        'Content-Type': 'application/json'
      },
    };
    axios.request(config)
      .then((response) => {
        setPKey(response.data.wallet)
      })
      .catch((error) => {
        console.log('Wallet merge error: ', error);
      });
  };

  const mergeWallet = (wallet) => {
    let data = JSON.stringify({
      "wallet": wallet
    });

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/user/${id}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        console.log('Wallet saved: ', response)
      })
      .catch((error) => {
        console.log('Wallet merge error: ', error);
      });
  };


  useEffect(() => {
    getWallet()
  }, []);

  return (
    <div className='wallet'>
      <ConnectionProvider endpoint={endpoint}>
        <WalletProvider wallets={wallets}>
          <WalletModalProvider>
              <h1>
                Welcome to Godlen mini game dashboard
              </h1>
              <p>{pKey ? 'Your confirmed solana address: ' : 'Connect your wallet to Godlen mini Game'}</p>
              <p className='wallet-address'>{pKey ? pKey.toString() : ''}</p>
              <div className='wallet-button-wrapper'>
                <button className='back-button' onClick={() => handleWallet()}><span>Select wallet</span></button>
                <button className='back-button' onClick={() => handleDisconect()}><span>Disconect wallet</span></button>
              </div>
              <a href="https://t.me/GodlenMemeTapper_bot/MemeTapper?startapp" className='back-button'><span>Back to game</span></a>

              <Popup className="modalWrapper" open={open} closeOnDocumentClick onClose={closeModal}>
                <div className="modal">
                  <a className="close" onClick={closeModal}>
                    &times;
                  </a>
                  <WalletConnect closeModal={closeModal} setPKey={setPKey} mergeWallet={mergeWallet} id={id} />
                </div>
              </Popup>
          </WalletModalProvider>
        </WalletProvider>
      </ConnectionProvider>
    </div>
    );
}

export default Wallet;
