
import './styles/tapsBar.css';
import thunder from '../images/thunder.svg'

function TapsBar({energy, energyBar}) {
  return (
    <div className="bar-container">
      <div className='bar-text'>
        <img src={thunder} width={20} alt="Thunder symbol" />
        <div className='text-wrapper'>
          <span className='big-text'>{energy}</span>
          <span className='small-text'> / {energyBar}</span>
        </div>
      </div>
      <div className='bar-wrapper'>
        <div style={{width: `${((energy/energyBar)*100) - 2}%`}} className='inner-bar'></div>
      </div>
    </div>
  );
}

export default TapsBar;
