import '../App.css';
import TappingArea from '../components/tappingArea';
import TapsBar from '../components/tapsBar';
import TapsCounter from '../components/tapsCounter';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { multiSmashLevels, energyLimitLevels, rechargingSpeedLevels } from '../constants/upgrades'

function Smash({ user, energy, setEnergy, clicks, setClicks, taps, setTaps, multi, setMulti }) {
  const webApp = window.Telegram.WebApp
  const multismash = parseInt(localStorage.getItem("multismash"));
  const energyLimitparseInt = parseInt(localStorage.getItem("energyLimit"));
  const rechargingSpeed = parseInt(localStorage.getItem("rechargingSpeed"));
  const [pendingSave, setPendingSave] = useState(false);
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();

  const getCurrentMvalue = (lvl) => {
    const currentLevel = multiSmashLevels.find(level => level.level === parseInt(lvl));
    setMulti(parseInt(localStorage.getItem("multiply")) || 1)
    return currentLevel ? currentLevel.upgrade * multi : 1;
  };

  const getCurrentEvalue = (lvl) => {
      const currentLevel = energyLimitLevels.find(level => level.level === parseInt(lvl));
      return currentLevel ? currentLevel.upgrade : 1;
  };

  const getCurrentRvalue = (lvl) => {
      const currentLevel = rechargingSpeedLevels.find(level => level.level === parseInt(lvl));
      return currentLevel ? currentLevel.upgrade : 1;
  };

  const saveDataCloseWindow = async () => {
    try {
      const data = JSON.stringify({
        "smashes": taps,
        "energy": energy,
        "lastVisit": formattedDate
      });
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/user/${user}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      const response = await axios.request(config);
      console.log('Saved: ', response.data);
      webApp.close();
    } catch (error) {
      console.log(error);
    } finally {
      setPendingSave(false);
    }
  };

  const handleTap = (e) => {
    if(energy < getCurrentMvalue(multismash)) return
    setPendingSave(true)
    setTaps(taps => taps + (1 * getCurrentMvalue(multismash)));
    getCurrentRvalue(rechargingSpeed)
    localStorage.setItem("smashes", JSON.stringify(taps));
    setEnergy(energy => energy - (1 * getCurrentMvalue(multismash)));
    const { clientX, clientY } = e;
    const newClick = { x: clientX, y: clientY - 30 };
    setClicks(prevClicks => [...prevClicks, newClick]);

    setTimeout(() => {
      setClicks(prevClicks => prevClicks.filter(click => click !== newClick));
    }, 2000);
  };

  useEffect(() => {
    localStorage.setItem("smashes", JSON.stringify(taps));
    localStorage.setItem("energy", JSON.stringify(energy));
  }, [taps, energy]);

  useEffect(() => {
    if (!pendingSave) return;

    const saveData = async () => {
      try {
        const data = JSON.stringify({
          "smashes": taps,
          "energy": energy
        });
        const config = {
          method: 'put',
          maxBodyLength: Infinity,
          url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/user/${user}`,
          headers: {
            'Content-Type': 'application/json'
          },
          data: data
        };
        const response = await axios.request(config);
        console.log('Saved: ', response.data);
      } catch (error) {
        console.log(error);
      } finally {
        setPendingSave(false);
      }
    };

    const saveTimeout = setTimeout(saveData, 2000);

    return () => clearTimeout(saveTimeout);

  }, [pendingSave, taps, user]);


  useEffect(() => {
    const handleBackButtonClick = () => {
      saveDataCloseWindow()
      console.log('Back button clicked on Page');
    };

    window.Telegram.WebApp.onEvent('backButtonClicked', handleBackButtonClick);

    console.log('Back button event listener set up for Page.');

    // Cleanup function to remove event listener
    return () => {
      window.Telegram.WebApp.offEvent('backButtonClicked', handleBackButtonClick);
    };
  }, []);


  return (
    <div className='page-inner'>
      <TapsCounter taps={taps} />
      <TappingArea handleTap={handleTap} clicks={clicks} energy={energy} />
      <TapsBar energy={energy} energyBar={getCurrentEvalue(energyLimitparseInt)} />
    </div>
  );
}

export default Smash;
