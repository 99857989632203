import './styles/modal.css';
import coin from '../images/coin-icon.svg'
import React from 'react'
import { ThreeDots } from 'react-loader-spinner';

const spinner = (
  <ThreeDots
    visible={true}
    height="16"
    width="40"
    color="rgba(235, 142, 19, 1)"
    radius="9"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
);

const x = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 10L22 22" stroke="#FCECAE" strokeWidth="3" strokeLinecap="round"/>
<path d="M22 10L10 22" stroke="#FCECAE" strokeWidth="3" strokeLinecap="round"/>
</svg>
const line = <svg width="64" height="8" viewBox="0 0 64 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="64" height="8" rx="4" fill="#FCECAE"/>
</svg>



function ModalBoost({close, handleUpgrade, modalInfo, setClose, loading}) {

    const smashes = parseInt(localStorage.getItem("smashes"));

  return (
    <div className="modal-overlay" style={{display: close ? "none" : "block"}}>
        <div className='modal-container'>
            <button onClick={() => setClose(true)} className='close-modal'>{x}</button>
            <button onClick={() => setClose(true)} className='slide-area'>{line}</button>
            <div className='content-inner-wrapper'>
                <img className='main-modal-image' src={modalInfo.image} alt="main icon" width={130} />
                <div className='inner-text'>
                    <span className='modal-title'>{modalInfo.title}</span>
                    <p>{modalInfo.description}</p>
                </div>
                <div className='price-wrap'>
                    <img className='coin-icon' src={coin} alt="main icon" width={20} />
                    {modalInfo.price}
                </div>
                <button className='get-it-button' style={{opacity: smashes > modalInfo.price ? '1' : '0.3' }} onClick={() => handleUpgrade(modalInfo.option)}>{loading ? spinner : 'Get it'}</button>
            </div>
        </div>
    </div>
  );
}

export default ModalBoost;
