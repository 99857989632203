import './welcomeDesktop.sass';
import qr from '../../images/qrcode-godlen.png'
import Header from './header/header'
import fishLeft from '../../images/fish-left.png'
import fishRight from '../../images/fish-right.png'


function WelcomeDesktop() {
  return (
    <div className="welcomeDesktopContainer">
      <Header />
      <img className='bgFishy' src={fishLeft} alt="background fish" />
      <div className='not-available'>
        <h2>Desktop is old school.<br/> Let's go Telegram!</h2>
        <img src={qr} alt="qrcode to rediret to mobile telegram" />
        <span>Scan this code to launch mobile game</span>
        <p>Or press this link to start the game: <a href='https://t.me/GodlenMemeTapper_bot/MemeTapper?startapp'>https://gamee.link</a></p>
      </div>
      <img className='bgFishy' src={fishRight} alt="background fish" />
    </div>
  );
}

export default WelcomeDesktop;
