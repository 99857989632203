import { NavLink } from "react-router-dom";
import './styles/footer.css';
import EnvelopeIcon from '../images/EnvelopeIcon.js'
import TargetIcon from '../images/TargetIcon.js'
import SmashIcon from '../images/SmashIcon.js'
import RocketIcon from '../images/RocketIcon.js'
import StatsIcon from '../images/StatsIcon.js'


function Footer() {
  return (
    <div className="menu-container">
        <NavLink className="menu-item-wrapper" to="/ref">{<EnvelopeIcon />}<span>Ref</span></NavLink>
        <NavLink className="menu-item-wrapper" to="/tasks">{<TargetIcon />}<span>Task</span></NavLink>
        <NavLink className="menu-item-wrapper" to="/smash">{<SmashIcon />}<span>Smash</span></NavLink>
        <NavLink className="menu-item-wrapper" to="/boost">{<RocketIcon />}<span>Boost</span></NavLink>
        <NavLink className="menu-item-wrapper" to="/stats">{<StatsIcon />}<span>Stats</span></NavLink>
    </div>
  );
}

export default Footer;
