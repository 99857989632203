import React, { useState } from 'react';
import { useWallet } from '@solana/wallet-adapter-react';
import { ThreeDots } from 'react-loader-spinner';

const spinner = (
  <ThreeDots
    visible={true}
    height="16"
    width="40"
    color="#000"
    radius="9"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
);
const connectText = <span className="connectText">Connect</span>;

const WalletConnect = ({ closeModal, setPKey, mergeWallet, id }) => {
  const wallet = useWallet();
  const [selected, setSelected] = useState('Phantom');

  const handleConnect = async (selectedWallet) => {
    try {
      setSelected(selectedWallet);
      wallet.select(selected);

      // Wait for the wallet to connect
      await wallet.connect();

      // Wait for the publicKey to be available
      while (!wallet.publicKey) {
        await new Promise(resolve => setTimeout(resolve, 100)); // Check every 100ms
      }

      setPKey(wallet.publicKey);
      closeModal();
      mergeWallet(wallet.publicKey);
    } catch (error) {
      console.error('Error connecting wallet:', error);
      wallet.select(selectedWallet);
      // Handle error
    }
  };
  console.log('wallet: ', wallet)
  return (
    <div className="walletTable">
      <h3>Connect Wallet</h3>
      <ul>
        {wallet
          ? wallet.wallets.map((wallet, index) => (
              <li key={index}>
                <button onClick={() => { handleConnect(wallet.adapter.name) }}>
                  <span><img height={24} src={wallet.adapter.icon} alt="wallet icon" />{wallet.adapter.name}</span>
                  <span>{selected === wallet.adapter.name ? connectText : wallet.adapter.readyState}</span>
                </button>
              </li>
            ))
          : <li className='mobileLinkWallet'><a href={`https://phantom.app/ul/browse/https://godlenmemetapper.godlenfish.com/wallet/${id}?ref=https://brunei-fear-roman-structured.trycloudflare.com`} target="_blank" rel="noreferrer">Phantom</a></li>}
          {wallet.wallets.length === 0 ? <li className='mobileLinkWallet'><a href={`https://phantom.app/ul/browse/https://godlenmemetapper.godlenfish.com/wallet/${id}?ref=https://brunei-fear-roman-structured.trycloudflare.com`} target="_blank" rel="noreferrer">Phantom</a></li> : null}
          {wallet.wallets.length === 0 ? <li className='mobileLinkWallet'><a href={`https://phantom.app/ul/browse/https://godlenmemetapper.godlenfish.com/wallet/${id}?ref=https://brunei-fear-roman-structured.trycloudflare.com`} target="_blank" rel="noreferrer">Solflare</a></li> : null}
      </ul>
    </div>
  );
};

export default WalletConnect;
