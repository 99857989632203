import './styles/stats.css';
import React, { useState } from 'react';
import axios from 'axios';
import { ThreeDots } from 'react-loader-spinner';

const spinner = (
  <ThreeDots
    visible={true}
    height="16"
    width="40"
    color="#fff"
    radius="9"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
);

function Total() {
  const [stats, setStats] = useState({});

  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/stats',
    headers: { 
      'Content-Type': 'application/json'
    }
  };

    axios.request(config)
      .then((response) => {
        setStats(response.data);
      })
      .catch((error) => {
        console.log(error);
      });


  return (
    <div className="stats-container">
      <div className='stats-item'>
        <span>Total smashes</span>
        <span>{stats?.totalCount ? stats?.totalCount : spinner}</span>
      </div>
      <div className='stats-item'>
        <span>Total players</span>
        <span>{stats?.userCount ? stats?.userCount : spinner}</span>
      </div>
      <div className='stats-item'>
        <span>Daily users</span>
        <span>{stats?.userCount ? stats?.onlineUserCount : spinner}</span>
      </div>
      <div className='stats-item'>
        <span>Online players</span>
        <span>{stats?.userCount ? stats?.dailyUserCount : spinner}</span>
      </div>
    </div>
  );
}

export default Total;
