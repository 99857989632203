import '../App.css';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import Header from '../components/header';
import Tabs from '../components/tabs';
import Tasks from '../components/tasks';
import { useNavigate } from 'react-router-dom';

function TasksPage({energy, taps}) {
  const [tab, setTab] = useState(0);
  const webApp = window.Telegram.WebApp
  const user = webApp.initDataUnsafe?.user
  const navigate = useNavigate();
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();

  const saveData = async () => {
    try {
      const data = JSON.stringify({
        "smashes": taps,
        "energy": energy,
        "lastVisit": formattedDate
      });
      const config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/user/${user.id}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      const response = await axios.request(config);
      console.log('Saved: ', response.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    saveData()
  }, [taps]);

  useEffect(() => {
    const handleBackButtonClick = () => {
      navigate(`/smash`);
    };

    window.Telegram.WebApp.onEvent('backButtonClicked', handleBackButtonClick);

    // Cleanup function to remove event listener
    return () => {
      window.Telegram.WebApp.offEvent('backButtonClicked', handleBackButtonClick);
    };
  }, []);
  
  return (
    <>
      <Header taps={taps} />
      <div className='page-body'>
        <Tabs setTab={setTab} tab={tab} />
        <Tasks message="Currently you don’t have tasks" setTab={setTab} tab={tab} />
      </div>
    </>
  );
}

export default TasksPage;
