import React from 'react';
import './styles/tasks.css';

function Tabs({ tab, setTab }) {
  return (
    <div className="tab-container">
      <button
        onClick={() => { setTab(0) }}
        className={`tab-item-wrapper ${tab === 0 ? 'active' : ''}`}
      >
        <span>Special</span>
      </button>
      <button
        onClick={() => { setTab(1) }}
        className={`tab-item-wrapper ${tab === 1 ? 'active' : ''}`}
      >
        <span>League</span>
      </button>
      <button
        onClick={() => { setTab(2) }}
        className={`tab-item-wrapper ${tab === 2 ? 'active' : ''}`}
      >
        <span>Referral</span>
      </button>
    </div>
  );
}

export default Tabs;