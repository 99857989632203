import './styles/loader.sass';
import { ThreeDots } from 'react-loader-spinner';

const spinner = (
  <ThreeDots
    visible={true}
    height="16"
    width="40"
    color="#fff"
    radius="9"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
);

function Loader() {
  return (
    <div className='loadingArea'>{spinner}<span>Loading stay still</span></div>
  );
}

export default Loader;
