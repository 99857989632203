import './styles/modal.css';
import React from 'react'

const x = <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10 10L22 22" stroke="#FCECAE" strokeWidth="3" strokeLinecap="round"/>
<path d="M22 10L10 22" stroke="#FCECAE" strokeWidth="3" strokeLinecap="round"/>
</svg>
const line = <svg width="64" height="8" viewBox="0 0 64 8" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect width="64" height="8" rx="4" fill="#FCECAE"/>
</svg>



function ModalRef({close, copyToClipboard, modalInfo, setClose}) {

    const smashes = parseInt(localStorage.getItem("smashes"));

  return (
    <div className="modal-overlay" style={{display: close ? "none" : "block"}}>
        <div className='modal-container-ref'>
            <button onClick={() => setClose(true)} className='close-modal'>{x}</button>
            <button onClick={() => setClose(true)} className='slide-area'>{line}</button>
            <div className='content-inner-wrapper-ref'>
                <a className='ref-share-button' href="https://t.me/share/url?url=https://t.me/GodlenMemeTapper_bot/MemeTapper?startapp=0f7b44fe&startApp=0f7b44fe&text=🎁+2.5k+Shares+as+a+first-time+gift">Send</a>
                <button className='ref-share-button' onClick={() => copyToClipboard(modalInfo.option)}>Copy link</button>
            </div>
        </div>
    </div>
  );
}

export default ModalRef;
