import axios from 'axios';

const userRegistered = async (user) => {
  if (user) {
    try {
      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/user/${user.id}`,
        headers: {
          'Content-Type': 'application/json'
        }
      };

      const response = await axios.request(config);
      return !!response.data.wallet; // Convert to boolean
      
    } catch (error) {
      // Handle errors (e.g., request failure)
      console.error('Error checking user registration:', error);
      return false; // Return false in case of error
    }
  } else {
    // If user is not provided, return false
    return false;
  }
};

export default userRegistered;
