import './styles/tasks.css';
import Special from './tabs/special';

function Tasks({message, setTab, tab}) {
  return (
    <div className="tasks-container">
      <div className='tasks-wrapper'>
        { tab === 0 ? 
            <Special /> :
          tab === 1 ?
            <span>{message}</span> :
          tab === 2 ? 
            <span>{message}</span> :
          null
        }
      </div>
    </div>
  );
}

export default Tasks;
