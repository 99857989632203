
import React, { useState } from 'react'
import Lottie from "lottie-react";
import idle from "../looties/idle_loop.json";
import punch1 from "../looties/punch_1.json";
import punch2 from "../looties/punch_2.json";
import punch3 from "../looties/punch_3.json";
import punch4 from "../looties/punch_4.json";
import './styles/tappingArea.css';
import { useHapticFeedback } from '@vkruglikov/react-telegram-web-app';
import { multiSmashLevels } from '../constants/upgrades';


function TappingArea({handleTap, clicks, energy}) {
  const [impactOccurred, notificationOccurred] = useHapticFeedback();
  const [multi, setMulti] = useState(parseInt(localStorage.getItem("multiply")) || 1);
  const multismash = parseInt(localStorage.getItem("multismash"));
  
  const punchAnimations = [punch1, punch2, punch3, punch4];

  const handleButtonClick = (e) => {
    if(energy < getCurrentMvalue(multismash)) return
    setMulti(parseInt(localStorage.getItem("multiply")) || 1)
    impactOccurred('light');
    notificationOccurred('success');  
    handleTap(e);

        // Play a random punch animation
        const randomIndex = Math.floor(Math.random() * punchAnimations.length);
        const randomPunchAnimation = punchAnimations[randomIndex];
        setAnimationData(randomPunchAnimation);
  };

  const [animationData, setAnimationData] = useState(idle);

  const getCurrentMvalue = (lvl) => {
    const currentLevel = multiSmashLevels.find(level => level.level === parseInt(lvl));
    return currentLevel ? currentLevel.upgrade : 0;
  };

  return (
      <div className="tapping-area">
        <button width={300} className='taps-button' onClick={(e) => handleButtonClick(e)}>
          {/* <img src={character} alt="Character tap" /> */}
          <div className='overlay-image-top'></div>
          <Lottie width={300} animationData={animationData} loop={animationData === idle}  onComplete={() => setAnimationData(idle)} />
          <div className='overlay-image'></div>
        </button>
        {clicks.map((click, index) => (
          <div key={index}>
            <style>
              {`
                .floating-number {
                  animation: moveup 7s infinite;
                }
                @keyframes moveup {
                  from {top: ${click.y}px; opacity: 1}
                  to {top: 0; opacity: 0}
                }
              `}
            </style>
            <span className={`floating-number ${click.fadeOut ? 'fade-out' : ''}`} style={{ left: click.x, top: click.y }}>+{multi * getCurrentMvalue(multismash)}</span>
          </div>
        ))}
      </div>
  );
}

export default TappingArea;
