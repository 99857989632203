// components/profilePage.js
import '../App.css';
import '../components/styles/profile.sass';
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/header';
import { ThreeDots } from 'react-loader-spinner';
import { useNavigate } from 'react-router-dom';

const spinner = (
  <ThreeDots
    visible={true}
    height="12"
    width="30"
    color="#EB8E13"
    radius="9"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
);

function ProfilePage() {
    const webApp = window.Telegram.WebApp
    const navigate = useNavigate();
    const user = webApp.initDataUnsafe?.user
    const [inputUsername, setInputUsername] = useState('');
    const [inputWallet, setInoutWallet] = useState('');
    const [loading, setLoading] = useState(false);
    const taps = localStorage.getItem("smashes");

    const getProfileData = () => {
        setLoading(true)
    
        let config = {
          method: 'get',
          maxBodyLength: Infinity,
          url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/user/${user?.id}`,
          headers: {
            'Content-Type': 'application/json'
          }
        };
        axios.request(config)
          .then((response) => {
            console.log('User info: ', response)
            setInputUsername(response.data.name)
            setInoutWallet(response.data.wallet)
            setLoading(false)
          })
          .catch((error) => {
            setLoading(false)
            console.log(error);
          });
      };

    const changeProfileData = () => {
      setLoading(true)
      let data = JSON.stringify({
        "name": inputUsername,
      });
      let config = {
        method: 'put',
        maxBodyLength: Infinity,
        url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/user/${user?.id}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };
      axios.request(config)
        .then((response) => {
          console.log('User info: ', response)
          setInputUsername(response.data.name)
          setInoutWallet(response.data.wallet)
          setLoading(false)
        })
        .catch((error) => {
          setLoading(false)
          console.log(error);
        });
    };

    useEffect(() => {
      getProfileData()
    }, []);

    useEffect(() => {
      const handleBackButtonClick = () => {
        navigate(`/smash`);
      };
  
      window.Telegram.WebApp.onEvent('backButtonClicked', handleBackButtonClick);
  
      // Cleanup function to remove event listener
      return () => {
        window.Telegram.WebApp.offEvent('backButtonClicked', handleBackButtonClick);
      };
    }, []);

    return (
        <div className='page-profile'>
           <Header taps={taps} />
            <div className='page-profile-body'>
              <span className='titleProfile'>Profile</span>
              <div className='wallet-input'>
                    <div className='inner-wallet-input'>
                      <span>Username</span>
                      <div>
                          <input value={inputUsername}  onChange={(e) => setInputUsername(e.target.value)} />
                      </div>
                      <button 
                        onClick={() => changeProfileData()}
                        >{loading ? spinner : 'Change'}</button>
                    </div>
                </div>
                <div className='wallet-input'>
                    <div className='inner-wallet-input'>
                      <span>Connect wallet</span>
                       <div>
                          <input value={inputWallet}  onChange={(e) => setInoutWallet(e.target.value)} />
                      </div>
                      {/* <button onClick={() => handleConnectWallet()}>{wallet !== '' ? "Disconnect" : "Connect"}</button> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProfilePage;
