import './styles/booster.css';
import axios from 'axios';
import {multiSmashLevels, energyLimitLevels, rechargingSpeedLevels} from '../constants/upgrades';
import { useState } from 'react';
import ModalBoost from './ModalBoost';
import { useNavigate } from 'react-router-dom';
import markIcon from '../images/mark.png'
import thunderIcon from '../images/thunder.svg'
import clock from '../images/clock.svg'

const arrowRight = <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M2 2L10 10L2 18" stroke="white" strokeWidth="3" strokeLinecap="round"/>
</svg>



function Upgrades({setEnergy, taps, setTaps}) {
    const user = JSON.parse(localStorage.getItem("user"));
    const [multismash, setMultismash] = useState(parseInt(localStorage.getItem("multismash")));
    const energyLimitparseInt = parseInt(localStorage.getItem("energyLimit") || 500);
    const [energyLimit, setEnergyLimit] = useState(parseInt(localStorage.getItem("energyLimit")));
    const [rechargingSpeed, setRechargingSpeed] = useState(parseInt(localStorage.getItem("rechargingSpeed")));
    const [close, setClose] = useState(true);
    const [loading, setLoading] = useState(false);
    const [modalInfo, setModalInfo] = useState({});
    const navigate = useNavigate();

    const handleModal = (option, info ) => {
        setModalInfo(info)
        setClose(option);
    }

    const getCurrentEvalue = (lvl) => {
        const currentLevel = energyLimitLevels.find(level => level.level === parseInt(lvl));
        return currentLevel ? currentLevel.upgrade : 1;
    };

    const getCurrentMprice = (lvl) => {
        const currentLevel = multiSmashLevels.find(level => level.level === parseInt(lvl));
        return currentLevel ? currentLevel.price : 0;
    };

    const getCurrentEprice = (lvl) => {
        const currentLevel = energyLimitLevels.find(level => level.level === parseInt(lvl));
        return currentLevel ? currentLevel.price : 0;
    };

    const getCurrentRprice = (lvl) => {
        const currentLevel = rechargingSpeedLevels.find(level => level.level === parseInt(lvl));
        return currentLevel ? currentLevel.price : 0;
    };

    const [mLevel, setMlevel] = useState(getCurrentMprice(multismash));
    const [eLevel, setElevel] = useState(getCurrentEprice(energyLimit));
    const [rLevel, setRlevel] = useState(getCurrentRprice(rechargingSpeed));

    const handleUpgrade = (option) => {

        // Check if option selected is not a bot option
        if(option === 4) return

        // Checks if have enough smasehs for level up
        const smashesAfterUpgrade = taps - (option === 1 ? mLevel : option === 2 ? eLevel : rLevel)
        console.log('Have: ', taps, 'Level price: ', mLevel, 'After: ', smashesAfterUpgrade)
        if(smashesAfterUpgrade < 1) return

        //Do not upgrade if max levels
        if(parseInt(multismash) === 10 && option === 1 ) return
        if(parseInt(energyLimit) === 10 && option === 2) return
        if(parseInt(rechargingSpeed) === 10 && option === 3) return 

        setLoading(true)
        const newMultismash = option === 1 ? parseInt(multismash) + 1 : multismash;
        const newEnergyLimit = option === 2 ? parseInt(energyLimit) + 1 : energyLimit;
        const newRechargingSpeed = option === 3 ? parseInt(rechargingSpeed) + 1 : rechargingSpeed;
        const newSmashBot = 1;
        localStorage.setItem("smashes", smashesAfterUpgrade)

        const data = {
            smashes : smashesAfterUpgrade,
            upgrades: {
                multismash: newMultismash,
                energyLimit: newEnergyLimit,
                rechargingSpeed: newRechargingSpeed,
                smashBot: newSmashBot
            }
        };

        const config = {
            method: 'put',
            maxBodyLength: Infinity,
            url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/user/${user}`,
            headers: { 
                'Content-Type': 'application/json'
            },
            data: JSON.stringify(data)
        };

        axios.request(config)
        .then((response) => {
            console.log('Got after upgrade: ', response.data)
            setMultismash(response.data.upgrades.multismash);
            setEnergyLimit(response.data.upgrades.energyLimit);
            setRechargingSpeed(response.data.upgrades.rechargingSpeed);
            setTaps(response.data.smashes);
            localStorage.setItem("multismash", response.data.upgrades.multismash);
            localStorage.setItem("energyLimit", response.data.upgrades.energyLimit);
            localStorage.setItem("rechargingSpeed", response.data.upgrades.rechargingSpeed);
            localStorage.setItem("smashes", response.data.smashes);
            setMlevel(getCurrentMprice(response.data.upgrades.multismash));
            setElevel(getCurrentEprice(response.data.upgrades.energyLimit));
            setRlevel(getCurrentRprice(response.data.upgrades.rechargingSpeed));
            setTimeout(() => {
                setLoading(false)
                // if(option === 2) {
                //     setEnergy(getCurrentEvalue(energyLimitparseInt))
                // }
                navigate(`/`);
            }, 1000);
        })
        .catch((error) => {
            setLoading(false)
            console.log(error);
        });
    }

  return (
    <div className="upgrade-container">
        <span className='upgrade-title'>Upgrades</span>
        <div className='upgrade-inner'>
            <button onClick={() => handleModal(false, {image: markIcon, title: 'Multismash', description: 'this is description', price: mLevel, option: 1})} className='upgrade-item'>
                <div className='upgrade-text-wrap'>
                <img src={markIcon} alt="menu item" width={40} />
                    <div className='upgrade-text'>
                        <span>Multismash</span>
                        <span>{mLevel} | {multismash} level</span>
                    </div>
                </div>
                {arrowRight}
            </button>
            <button onClick={() => handleModal(false, {image: thunderIcon, title: 'Energy limit', description: 'this is description', price: eLevel, option: 2})} className='upgrade-item'>
                <div className='upgrade-text-wrap'>
                <img src={thunderIcon} alt="menu item" width={40} />
                    <div className='upgrade-text'>
                        <span>Energy limit</span>
                        <span>{eLevel} | {energyLimit} level</span>
                    </div>
                </div>
                {arrowRight}
            </button>
            <button onClick={() => handleModal(false, {image: clock, title: 'Recharging speed', description: 'this is description', price: rLevel, option: 3})} className='upgrade-item'>
                <div className='upgrade-text-wrap'>
                <img src={clock} alt="menu item" width={40} />
                    <div className='upgrade-text'>
                        <span>Recharging speed</span>
                        <span>{rLevel} | {rechargingSpeed} level</span>
                    </div>
                </div>
                {arrowRight}
            </button>
            <button onClick={() => handleModal(false, {image: markIcon, title: 'Smash bot', description: 'this is description', price: '1,000,000', option: 4})} className='upgrade-item'>
                <div className='upgrade-text-wrap'>
                <img src={markIcon} alt="menu item" width={40} />
                    <div className='upgrade-text'>
                        <span>Smash bot <span className='coming'>(Coming soon)</span></span>
                        <span>1,000,000 | 1 level</span>
                    </div>
                </div>
                {arrowRight}
            </button>
        </div>
        <ModalBoost close={close} handleUpgrade={handleUpgrade} modalInfo={modalInfo} setClose={setClose} loading={loading} />
    </div>
  );
}

export default Upgrades;
