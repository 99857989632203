import '../App.css';
import '../components/styles/task.sass';
import axios from 'axios';
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from "react-router-dom";
import CoinIcon from '../images/CoinIcon.js';
import { useTelegram } from "../hooks/useTelegram";
import { ThreeDots } from 'react-loader-spinner';

const spinner = (
  <ThreeDots
    visible={true}
    height="14"
    width="35"
    color="#EB8E13"
    radius="9"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
);

function TaskPage({taps, setTaps}) {
  const navigate = useNavigate();
  const location = useLocation();
  const { webApp } = useTelegram();
  const user = JSON.parse(localStorage.getItem("user") || null);
  const { name, reward, task, taskId } = location.state?.task || {};
  const [claim, setClaim] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);
  const [claimed, setClaimed] = useState(false);

  const handleTaskStart = (link) => {
    setClaim(true);
    webApp.openLink(link, { try_instant_view: false });
  };

  const claimReward = async (taskId) => {
      setLoading(true)
      console.log('User id: ', taskId, user)
      let data = JSON.stringify({
        "taskId": taskId
      });

      const config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/claim/${user}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      axios.request(config)
      .then((response) => {
        console.log('Returned value', response.data);
        localStorage.setItem("smashes", response.data.user.smashes);
        setTaps(response.data.user.smashes)
        setClaimed(true);
        setClaim(false);
        setLoading(false);
        setMessage(response.data.message);
      })
      .catch((error) => {
        setMessage('Failed to claim, do you realy completed task?')
        setLoading(false);
        console.log(error);
      });

  };

  const checkIfClaimed = async() => {
    setLoading(true)
    try {
      let data = JSON.stringify({
        "taskId": taskId
      });

      const config = {
        method: 'get',
        maxBodyLength: Infinity,
        url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/user/${user}`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      const response = await axios.request(config);
      const result = response.data.tasks.filter((task) => task.taskId === taskId);
      setClaimed(result[0].status)
      setLoading(false)
      console.log('Got this: ', result[0].status)
      
    } catch (error) {
      setLoading(false)
      console.error('Error while trying to claim', error);
    }
  }

  useEffect(() => {
    const handleBack = () => {
      navigate("/tasks");
      console.log('Back button clicked on Page');
    };

    window.Telegram.WebApp.onEvent('backButtonClicked', handleBack);

    console.log('Back button event listener set up for Page.');

    // Cleanup function to remove event listener
    return () => {
      window.Telegram.WebApp.offEvent('backButtonClicked', handleBack);
    };
  }, []);

  useEffect(() => {
    checkIfClaimed()
  }, []);

  return (
    <div className='page-task'>
      <h2>{name}</h2>
      <p>We regularly share valuable content on our socials.</p>
      <p>Join us there and get the rewards.</p>
      <div className='reward-wrapper'>
        <CoinIcon />
        <div className='reward-text-wrapper'>
          <span>Reward</span>
          <span>{reward}</span>
        </div>
      </div>
      <h3>Your tasks</h3>
      <div className='tasks-wrapper'>
          <div className='task-wrapper'>
            <span>{task.name}</span>
            { !claim && !claimed ? 
              <button onClick={() => handleTaskStart(task.link)}>{loading ? spinner : 'Open'}</button> :
              !claim && claimed ?
                <button disabled>{loading ? spinner : 'CLAIMED'}</button>
              :
                <button onClick={() => claimReward(taskId)}>{loading ? spinner : 'CLAIM'}</button>
            }
          </div>
          <span className='messageWrapper'>{message}</span>
      </div>
    </div>
  );
}

export default TaskPage;
