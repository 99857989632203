import '../App.css';
import '../components/styles/welcome.sass';
import { NavLink } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setUsername } from '../redux/slices/userSlice';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import isRegistered from '../functions/userRegistered';
import { ThreeDots } from 'react-loader-spinner';

const spinner = (
  <ThreeDots
    visible={true}
    height="30"
    width="80"
    color="#fff"
    radius="9"
    ariaLabel="three-dots-loading"
    wrapperStyle={{}}
    wrapperClass=""
  />
);

function WelcomePage({setEnergy, setTaps}) {
  const dispatch = useDispatch();
  const webApp = window.Telegram.WebApp;
  const user = webApp.initDataUnsafe?.user;
  const [inputUsername, setInputUsername] = useState(user?.username);
  const [inputWallet, setInputWallet] = useState('');
  const [wallet, setWallet] = useState('');
  const [loading, setLoading] = useState(false);
  const [referralCode, setReferralCode] = useState('');
  const currentDate = new Date();
  const formattedDate = currentDate.toISOString();
  const navigate = useNavigate();

  const registerUser = async (user) => {
    if (!user || !user.id) {
      console.error('Invalid user data');
      return;
    }

    try {
      const registeredUser = await isRegistered(user);
      if (registeredUser) {
        loginUser();
        return;
      }

      setLoading(true);
      dispatch(setUsername(inputUsername));

      let data = JSON.stringify({
        "telegramId": user.id,
        "daily": {
          "dailyRestores": "3",
          "dailyMultiply": "3"
        },
        "upgrades": {
          "multismash": "1",
          "energyLimit": "1",
          "rechargingSpeed": "1",
          "smashBot": "1"
        },
        "name": inputUsername,
        "wallet": inputWallet,
        "energy": 500,
        "referralCode": referralCode ? referralCode : null
      });

      let config = {
        method: 'post',
        maxBodyLength: Infinity,
        url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/user`,
        headers: {
          'Content-Type': 'application/json'
        },
        data: data
      };

      const response = await axios.request(config);

      localStorage.setItem("auth", JSON.stringify(true));
      localStorage.setItem("smashes", response.data.smashes);
      localStorage.setItem("dailyMultiply", response.data.daily.dailyMultiply);
      localStorage.setItem("dailyRestores", response.data.daily.dailyRestores);
      localStorage.setItem("energyLimit", response.data.upgrades.energyLimit);
      localStorage.setItem("multismash", response.data.upgrades.multismash);
      localStorage.setItem("rechargingSpeed", response.data.upgrades.rechargingSpeed);
      localStorage.setItem("smashBot", response.data.upgrades.smashBot);

      setLoading(false);
      if (response.data.wallet) {
        setInputWallet(response.data.wallet);
        setWallet(response.data.wallet);
        navigate(`/smash`);
      } else {
        if (inputWallet !== '') {
          mergeWallet();
        } else {
          webApp.close();
        }
      }
    } catch (error) {
      setLoading(false);
      console.log(error);
      loginUser();
    }
  };

  const loginUser = () => {
    setLoading(true);
    let data = JSON.stringify({
      "lastVisit": formattedDate
    });

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/user/${user?.id}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        localStorage.setItem("auth", JSON.stringify(true));
        localStorage.setItem("smashes", response.data.smashes);
        localStorage.setItem("energy", response.data.energy);
        localStorage.setItem("dailyMultiply", response.data.daily.dailyMultiply);
        localStorage.setItem("dailyRestores", response.data.daily.dailyRestores);
        localStorage.setItem("energyLimit", response.data.upgrades.energyLimit);
        localStorage.setItem("multismash", response.data.upgrades.multismash);
        localStorage.setItem("rechargingSpeed", response.data.upgrades.rechargingSpeed);
        localStorage.setItem("smashBot", response.data.upgrades.smashBot);
        localStorage.setItem("wallet", response.data.wallet);
        setEnergy(response.data.energy);
        setTaps(response.data.smashes);
        setInputWallet(response.data.wallet);
        setWallet(response.data.wallet);
        if (response.data.wallet) {
          navigate(`/smash`);
        } else {
          mergeWallet();
        }
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  const mergeWallet = () => {
    setLoading(true);
    let data = JSON.stringify({
      "wallet": inputWallet
    });

    let config = {
      method: 'put',
      maxBodyLength: Infinity,
      url: `https://godlen-mini-game-backend-a86ee214d4d2.herokuapp.com/user/${user?.id}`,
      headers: {
        'Content-Type': 'application/json'
      },
      data: data
    };
    axios.request(config)
      .then((response) => {
        setInputWallet(response.data.wallet);
        setWallet(response.data.wallet);
        setLoading(false);
        if (response.data.wallet) {
          navigate(`/smash`);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.log('Wallet merge error: ', error);
      });
  };

  const handleConnectWallet = () => {
    setLoading(true);
    const webApp = window.Telegram.WebApp;
    const innerUser = webApp.initDataUnsafe?.user;
    registerUser(innerUser);
    if (inputWallet === '') {
      const url = `/wallet/${innerUser.id}`;
      webApp.openLink(url, { try_instant_view: false });
    }
  };

  useEffect(() => {
    const checkRegistration = async () => {
      if (user?.id) {
        try {
          const registeredUser = await isRegistered(user);
          if (registeredUser) {
            loginUser();
          }
        } catch (error) {
          console.error('Error checking registration status:', error);
        }
      }
    };

    checkRegistration();
  }, [user]);

  useEffect(() => {
    const getReferralCodeFromURL = () => {
      const urlParams = window.Telegram.WebApp.initDataUnsafe.start_param;
      if (urlParams) {
        setReferralCode(urlParams);
      }
    };

    getReferralCodeFromURL();
  }, []);

  useEffect(() => {
    const handleBackButtonClick = () => {
      webApp.close();
    };

    window.Telegram.WebApp.onEvent('backButtonClicked', handleBackButtonClick);

    // Cleanup function to remove event listener
    return () => {
      window.Telegram.WebApp.offEvent('backButtonClicked', handleBackButtonClick);
    };
  }, []);

  return (
    <div className='page-welcome'>
      {!loading ?
        <div className='page-welcome-body'>
          <h1>Welcome!</h1>
          <p>Please choose your username and connect your Phantom or other $SOL tokens supporting wallet</p>
          <div className='username-input'>
            <span>1. Choose your username</span>
            <div className='inner-username-input'>
              <span>Username</span>
              <input
                type="text"
                value={inputUsername}
                onChange={(e) => setInputUsername(e.target.value)}
              />
            </div>
          </div>
          <div className='wallet-input'>
            <span>2. Connect your wallet</span>
            <div className='inner-username-input'>
              <input
                type="text"
                value={inputWallet}
                onChange={(e) => setInputWallet(e.target.value)}
              />
              <button onClick={() => handleConnectWallet()}>{wallet !== '' ? "Disconnect" : "Connect"}</button>
            </div>
            <p>Paste your wallet here and press connect or leave empty and press connect to connect directly</p>
          </div>
          {inputWallet !== '' ? <button onClick={() => handleConnectWallet()} className='lets-play-button'>Let's play!</button> : null}
        </div>
        : <div className='loadingArea'>{spinner}<span>Loading your time thief</span></div>}
    </div>
  );
}

export default WelcomePage;
