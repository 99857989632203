import './styles/ref.css';

function MyReferrals({copyToClipboard, setClose}) {
  return (
    <div className="referral-container">
        <span>My referrals</span>
        <button onClick={() => setClose(false)}className="invite-button"><span>Invite a friend</span></button>
        {/* <a className="invite-button" href="https://t.me/share/url?url=https://t.me/GodlenMemeTapper_bot/MemeTapper?startapp=0f7b44fe&startApp=0f7b44fe&text=🎁+2.5k+Shares+as+a+first-time+gift"><span>Invite a friend</span></a> */}
    </div>
  );
}

export default MyReferrals;
